import React, {useEffect, useRef, useState} from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import {Row, Col} from "styled-bootstrap-grid";
import Img from "gatsby-image";
import Container from "../components/Core/Container";
import breakpoints from "../styles/breakpoints";
import Section from "../components/Core/Section";

// icons
import Icon from "../components/Core/Icon";

// objects
import SearchForm from "../components/SearchForm.js";
import WhitelabelSearchForm from "../components/WhitelabelSearchForm";
import Hero from "../components/Property/Hero";
import Ratings from "../components/Property/Ratings";
import FormImageBlock from "../components/Property/FormImageBlock";
import Copy from "../components/Core/Copy";
import Map from "../components/Property/Map";
import BlogList from "../components/BlogList";
import PropertyList from "../components/PropertyList";
import PropertyListExternal from "../components/PropertyListExternal";
import TrustPilot from "../components/Core/TrustPilot";
import UnitList from "../components/Property/UnitList";

const Image = styled.img`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: 18rem;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.md}) {
    max-height: 460px;
  }
  @media only screen and (min-width: ${breakpoints.lg}) {
    padding-bottom: 4rem;
    flex-direction: column;
  }
`;

const TestContainer = styled.div`
    position: absolute;
    z-index: 10;
    right: 1.25rem;
    left: 1.25rem;
    height: auto;
    color: var(--white);
    background: var(--primary);
    padding: 1.25rem;
    border-radius: 5px;
    top: 50%;
    transform: translateY(-50%);

    @media only screen and (min-width: ${breakpoints.lg}) {
        right: unset;
        min-width: 26rem;
    } 

    h1 {
        padding: 0;
        margin: 0;
    }

    span {
        display: block;
        font-size: 1rem;
        font-weight: 400; 
    }

    button {
        background: var(--secondary);
        color: var(--white);
        cursor: pointer;
        outline: none;
        text-decoration: none;
        box-sizing: border-box;
        padding: 1.094rem 0.5rem 1.094rem 0.5rem;
        border: 1px solid var(--secondary);
        text-align: center;
        font-weight: 600;
        width: 100%;
        transition: .3s;
        margin: 1.25rem 0 0;

        &:hover,
        &:focus {
            border-color: var(--lightenButton);
            background: var(--lightenButton);
            color: var(--white);
            transition: .3s;
        }
    }
`;

const SearchFormContainer = styled.div`
  position: relative;
  z-index: 10;
`;

const Name = styled.h1`
    margin: 0 0 .5rem 0;

    span {
        font-size: 1rem;
        line-height: 1.4;
        display: block;
        margin: .5rem 0 0 0;
        font-weight: 400;
    }

    &::first-letter {
        text-transform: uppercase;
    }
`;

const Features = styled.div`

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    margin-bottom: 0;

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
        column-gap: .5rem;
    }

    @media only screen and (min-width: ${breakpoints.lg}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  li {
      display: flex;
      align-items: center;
  }

  .icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.5rem;
      background: var(--primary);
      fill: var(--white);
      border-radius: 50%;
      margin-right: 1rem;
  }
`;

export default function PropertyPage({data}) {
    const image = data.property.imageUrl;
    const property = data.property.pageBuilder;
    const [containerWidth, setContainerWidth] = useState(0);
    const parentRef   = useRef(null);


    function useWindowSize() {
        useEffect ( () => {
            function handleResize() {
                if (parentRef.current) {
                    const parentWidth  = parentRef.current.offsetWidth;
                    setContainerWidth(parentWidth);

                }
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, [parentRef]);
        return containerWidth;
    }


    function dataLayerPush (outboundEvent, outbountURL) {
        if (typeof window !== "undefined"){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({"event": outboundEvent,  "outbound-destination": outbountURL});
        }
    }

    function conductSearch(event) {
        event.preventDefault();
        const newDate = getNextDayOfWeek(new Date(), 6);
        dataLayerPush("outbound-click", "snaptrip property");
        createUrl(newDate);
    }

    function openInNewTab(url) {
        const win = window.open(url, "_blank");
        win.focus();
    }

    function getNextDayOfWeek(date, dayOfWeek) {
    
        const resultDate = new Date(date.getTime());
    
        resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    
        return resultDate;
    }

    function createUrl(newDate) {
        let entryPage;
        let exitPage;

        if (typeof window !== "undefined") {
            entryPage = `https://www.holidayparks.com${  window.sessionStorage.getItem("landingUrl")}`;
            exitPage = window.location.href;
        }
        const utmParams = `&utm_source=holidayparks&utm_medium=referral&utm_campaign=lodge-Unlabelled-landing-${entryPage}-click-${exitPage}`;
        let url = "https://www.snaptrip.com";

        setTimeout(function(){ 
            url = `${data.property.url  }?check_in_on=${String(newDate).replace(/ /g, "%20")}&nights=2`;
            openInNewTab(url+utmParams);
        }, 100); 
    }

    function getIcon(amenity) {

        let icon;
        switch(true) {
            case /hot-tub|hottub|hot tub/gi.test(amenity):
                icon = "icon-hot-tub";
                break;
            case /dog |pet|dogs/gi.test(amenity):
                icon = "icon-pet";
                break;
            case /wifi|wi-fi|broadband/gi.test(amenity):
                icon = "icon-thunderbolt";
                break;
            case /garden|secluded/gi.test(amenity):
                icon = "icon-flower";
                break;
            case /parking/gi.test(amenity):
                icon = "icon-parking";
                break;
            case /heating/gi.test(amenity):
                icon = "icon-heater";
                break;
            case /pub|beer|bar /gi.test(amenity):
                icon = "icon-beer";
                break;
            case /walk |hike /gi.test(amenity):
                icon = "icon-walk";
                break;
            case /fish |fishing/gi.test(amenity):
                icon = "icon-fishing";
                break;
            default:
                icon = "icon-star";
        }
        return icon;
        
    }

    useWindowSize();

    const content = (property || [])
        .map((c) => {
            let el;
            switch (c._type) {
                case "searchForm":
                    el = <SearchForm key={c._key} {...c} />;
                    break;
                case "whitelabelSearchForm":
                    el = <WhitelabelSearchForm key={c._key} {...c} />;
                    break;
                case "propHero":
                    el = <Hero key={c._key} {...c} property />;
                    break;
                case "trustpilot":
                    el = <TrustPilot key={c._key} {...c} />;
                    break;
                case "ratingsBlock":
                    el = <Ratings key={c._key} {...c} />;
                    break;
                case "formImageBlock":
                    el = <FormImageBlock key={c._key} {...c} />;
                    break;
                case "copy":
                    el = <Copy key={c._key} {...c} property />;
                    break;
                case "map":
                    el = <Map key={c._key} {...c} />;
                    break;
                case "blogList":
                    el=<BlogList key={c._key} {...c} />;
                    break;
                case "propertyList":
                    el=<PropertyList key={c._key} {...c} property />;
                    break;
                case "propertyListExternal":
                    el=<PropertyListExternal key={c._key} {...c} property />;
                    break;
                case "unitList":
                    el=<UnitList key={c._key} {...c} property />;
                    break;
                default:
                    el = null;
            }
            return el;
        });

    return (
      <>
        <HeroSection>
          {image && <Image src={image} loading="eager" critical="true" /> }
          <Container>
            <TestContainer>
              <h1>
                {data.property.name}
                <span>
                  {data.property.region}
                </span>
              </h1>
              <button onClick={() => conductSearch(event)}>Check availability</button>
            </TestContainer>
          </Container>
        </HeroSection>
        <Container>
          <div ref={parentRef}>
            <Section>
              {data.property.features && data.property.features.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <Features>
                      <h3>Features include:</h3>
                      <ul>
                        {data.property.features.map((feature, index) => {
                            const icon  = getIcon(feature);
                                return ( 
                                  <li key={index}>
                                    <Icon name={icon} />
                                    {feature}
                                  </li>
                                );
                            })}
                      </ul>
                    </Features>
                  </Col>
                </Row>
              ) : "" }
              {data.property.facilities.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <Features>
                      <h3 id="glance">At a glance</h3>
                      <ul>
                        {data.property.facilities.map((facility, index) => {

                            const icon  = getIcon(facility);
                            return ( 
                              <li key={index}>
                                <Icon name={icon} />
                                {facility}
                              </li>
                            );
                        })}
                      </ul>
                    </Features>
                  </Col>
                </Row>
              ) : "" }
            </Section>
          </div>
        </Container>
        {content}
      </>
    );
}

export const query = graphql`
		query($slug: String!) {
        property: sanityPark( slug: { current: { eq: $slug } }) {
            name
            brandName
            imageUrl
            brandImage {
                image {
                    asset {
                        fluid(maxWidth: 1200) {
                             ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            slug {
                current
            }
            parkUrl
            stUrl
            bookingUrl
            bookingComUrl
            hoseasonsUrl
            region
            town
            county
            address
            postcode
            geopoint {
                lat
                lng
            }
            facilities
            accomodation
            description
            titleSEO
			descriptionSEO
			keywordsSEO
            noindex
			pageBuilder {
				... on SanitySearchForm {
						_key
						_type
                        id
						apartmentFilter
						color
						beachFilter
						cottageFilter
						description
						gardenFilter
						heading
						hottubFilter
						lodgeFilter
						luxuryFilter
						wifiFilter
						parkingFilter
						fireFilter
						babyFilter
						poolFilter
						gamesFilter
						enclosedFilter
						pubFilter
						secludedFilter
						accessibleFilter
						override
						overrideDate
						overrideNights
						overrideGuests
						petFilter
						tagline
				}
				... on SanityTrustpilot {
                    _key
                    _type
                }
                ... on SanityWhitelabelSearchForm {
                    _key
                    _type
                    heading
                    tagline
                    id
                    description
                    override
                    overrideDate
                    overrideNights
                    overrideGuests
                    color
                    scrollToForm
                    vrSort
                    apartmentFilter
                    holidayHomesFilter
                    villaFilter
                    hotelFilter
                    lodgeFilter
                    petsFilter
                    wifiFilter
                    coastalFilter
                    luxuryFilter
                    poolFilter
                    hotTubFilter
                }
                ... on SanityPropertyList {
                    _key
                    _type
                    heading
                    tagline
                    id
                    properties {
                        name
                        brandName
                        imageUrl
                        brandImage {
                            image {
                                asset {
                                    fluid(maxWidth: 1200) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                        slug {
                            current
                        }
                        parkUrl
                        stUrl
                        bookingUrl
                        bookingComUrl
                        hoseasonsUrl
                        region
                        town
                        county
                        address
                        postcode
                        geopoint {
                            lat
                            lng
                        }
                        facilities
                        accomodation
                        description
                    }
                }
                ... on SanityUnitList {
                    _key
                    _type
                    heading
                    tagline
                    id
                    cards {
                        name
                        facilities
                        url
                        description
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityPropertyListExternal {
                    _key
                    _type
                    heading
                    tagline
                    id
                    cards {
                        name
                        url
                        region
                        description
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityBlogList {
					_key
					_type
					subtitle
					title
                    id
					blogposts {
						image {
                            alt 
                            image {
                                asset {
                                    fluid(maxWidth: 1000) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
						}
						slug {
							current
						}
						title
					}
				}
				... on SanityPropHero {
                    _key
                    _type
                    image {
                        asset {
                            fluid(maxWidth: 1200) {
                                    ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
                ... on SanityRatingsBlock {
                    _key
                    _type
                    heading
                    id
                    ratings {
                        label
                        percentage
                    }
                }
                ... on SanityCopy {
                    _key
                    _type
                    _rawCopy
                    showBothAccordion
                    showMobileAccordion
                    showDesktopAccordion
                } 
                ... on SanityMap {
                    _key
                    _type
                    id
                    location {
                        lat
                        lng
                    }
                }
                ... on SanityFormImageBlock {
                    _key
                    _type
                    subHeading
                    heading
                    formCopy
                    id
                    icon {
                        alt
                        image {
                            asset {
                                url
                            }
                        }
                    }   
                    image {
                        alt
                        image {
                            asset {
                                fluid(maxWidth: 1200) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                    }
                }
			}
			
        }
		regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
                showInNav
			}
		}
		settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {
			description
			id
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
                    fixed(height: 600, width: 1200) {
                        src
                    }
				}
			}
            signUpDisplay
          signUpBackgroundColor
          signUpHeading
          zapierEndpoint
		}
	}
`;
